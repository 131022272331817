<script setup lang="ts">
import { storeToRefs } from 'pinia'

import CookiebotConsentLink from '~/components/shared/footer/CookiebotConsentLink.vue'
import LanguageSwitcher from '~/components/ui/LanguageSwitcher.vue'

import { useUserStore } from '~/stores/user'

const currentYear = new Date().getFullYear()

const { IS_LOGGED_IN } = storeToRefs(useUserStore())
</script>

<template>
  <div
    class="tw-flex tw-flex-col tw-items-center tw-space-y-2 tw-text-center sm:tw-flex-row-reverse sm:tw-items-start sm:tw-space-y-0 sm:tw-space-x-reverse sm:tw-text-left"
  >
    <LanguageSwitcher v-if="!IS_LOGGED_IN" />
    <div
      class="tw-flex tw-w-full tw-flex-col tw-space-y-2 lg:tw-flex-row lg:tw-space-x-10 lg:tw-space-y-0"
    >
      <div>&copy; Groover 2018 - {{ currentYear }}</div>
      <NuxtLinkLocale to="/cgs/">{{
        $t('components.shared.footer.legalTerms')
      }}</NuxtLinkLocale>
      <CookiebotConsentLink />
    </div>
  </div>
</template>
