<script setup lang="ts">
/**
 * This component is responsible for displaying the link that shows the cookiebot consent manager.
 * See https://www.cookiebot.com/en/developer/ for details.
 */

function alertDevMode() {
  return (
    import.meta.dev && alert("Cookiebot doesn't work in the dev environment")
  )
}
</script>

<template>
  <a
    id="CookiebotConsentLink"
    href="javascript: Cookiebot.renew()"
    @click="alertDevMode"
  >
    {{ $t('components.shared.footer.manageYourCookies') }}
  </a>
</template>
