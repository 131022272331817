<script setup lang="ts">
import { useGetMediaUrl } from '~/composables/useGetMediaUrl'

import { trackEvent } from '~/helpers/LegacyTrackEvent'

const socialMediaLinks: { name: string; link: string; icon: string }[] = [
  {
    name: 'Facebook',
    link: 'https://www.facebook.com/HeyGroover/',
    icon: 'fa-facebook-square',
  },
  {
    name: 'Instagram',
    link: 'https://www.instagram.com/groover.music/',
    icon: 'fa-instagram',
  },
  {
    name: 'YouTube',
    link: 'https://www.youtube.com/channel/UCKOgjV7c-ElSjwUzsZvIvWQ',
    icon: 'fa-youtube',
  },
  {
    name: 'Spotify',
    link: 'https://open.spotify.com/user/xa5jpotdh03zb469lofbv9u0m?si=Yyat1XvjSvCEfEAe2w4n-g',
    icon: 'fa-spotify',
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/heygroover/',
    icon: 'fa-x-twitter',
  },
]

const route = useRoute()
const { getMediaUrl } = useGetMediaUrl()

function trackExternalVisit({ name }: { name: string }): void {
  trackEvent(
    {
      category: 'External Visit',
      action: name,
      origin: 'Footer',
    },
    route,
  )
}
</script>

<template>
  <div class="topContainer">
    <NuxtLinkLocale
      class="tw-mb-3 tw-flex tw-items-center tw-justify-center sm:tw-justify-start"
      to="/"
    >
      <img :src="getMediaUrl('GrooverLogoWhite.svg')" alt="Groover logo" />
    </NuxtLinkLocale>
    <div class="socialMediaLinks tw-space-x-3">
      <a
        v-for="{ name, link, icon } in socialMediaLinks"
        :key="name"
        :href="link"
        target="_blank"
        @click="trackExternalVisit({ name })"
      >
        <span class="tw-sr-only">{{ name }}</span>
        <i class="fab tw-text-white" :class="icon" role="icon" />
      </a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.topContainer {
  @apply tw-mx-auto;
  @screen sm {
    @apply tw-mx-0;
  }
  .socialMediaLinks {
    @apply tw-mx-auto tw-flex tw-w-full tw-justify-center;
    @screen sm {
      @apply tw-justify-start;
    }
  }
}

i {
  @apply tw-cursor-pointer;
}

.groover {
  @apply tw-font-sans tw-font-bold tw-leading-none tw-text-white;
  font-size: 22px;
}
</style>
