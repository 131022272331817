<template>
  <div class="languageSwitcher tw-relative">
    <transition name="fade" mode="out-in">
      <div
        v-if="isMenuVisible"
        class="menuDialog tw-pointer tw-absolute tw-right-0 tw-flex tw-flex-col tw-bg-white"
      >
        <VText
          v-for="lang in <Lang[]>$i18n.localeCodes"
          :key="lang"
          cfg="sans/14/medium"
          color="black"
          class="lang tw- tw-flex tw-h-10 tw-w-full tw-cursor-pointer tw-items-center tw-justify-center tw-px-5 hover:tw-bg-gray-200"
          @click="setLang({ lang })"
        >
          {{ $t(`locales.${lang}`) }}
        </VText>
      </div>
    </transition>

    <div
      class="tw-flex tw-w-full tw-justify-center sm:tw-w-auto sm:tw-justify-end"
    >
      <span class="tw-mr-2">{{ $t('components.ui.langSwitcher.lang') }}</span>
      <div class="tw-flex" @click="isMenuVisible = !isMenuVisible">
        <b class="tw-mr-1 tw-cursor-pointer tw-select-none">{{
          $t(`locales.${$i18n.locale}`)
        }}</b>
        <i
          class="fas fa-chevron-down chevronIcon tw-text-white"
          :class="{ chevronIconRotated: isMenuVisible }"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { mapState } from 'pinia'
import { defineComponent } from 'vue'

import VText from '~/components/ui/VText.vue'

import patchLangMixin from '~/mixins/band/patchLang'
import eventsMixin from '~/mixins/events'

import { useUserStore } from '~/stores/user'

import type Lang from '~/types/lang'

export default defineComponent({
  components: { VText },
  mixins: [eventsMixin, patchLangMixin],
  data() {
    return {
      isMenuVisible: false,
    }
  },
  computed: {
    ...mapState(useUserStore, {
      IS_LOGGED_IN: 'IS_LOGGED_IN',
      USER_EMAIL: 'email',
    }),
  },
  methods: {
    setLang({ lang }: { lang: Lang }) {
      const origin_lang = this.$i18n.locale

      this.isMenuVisible = false
      if (this.IS_LOGGED_IN) this.patchLang(lang, this.USER_EMAIL, false)
      else this.updateStoreSideLang(lang)

      // TODO: check this switchLocalePath
      const switchLocalePath = useSwitchLocalePath()
      this.$router.push(switchLocalePath(lang))
      this.trackEvent({
        category: 'i18n',
        action: 'Toggle',
        target_lang: lang,
        origin_lang,
      })
    },
  },
})
</script>

<style scoped lang="scss">
.languageSwitcher {
  width: fit-content;
}
.menuDialog {
  @apply tw-py-2;
  width: 102px;
  bottom: 24px;
}
.chevronIcon {
  transform-origin: center;
  align-self: center;
  transition: transform 0.3s;
}
.chevronIconRotated {
  transform: rotate3d(1, 0, 0, 180deg);
}
.lang {
  transition: background-color 0.25s;
}
</style>
