<script setup lang="ts">
import { storeToRefs } from 'pinia'

import FooterBottom from './bottom.vue'
import FooterContent from './content.vue'
import FooterTop from './top.vue'

import { useMiscDraftStore } from '~/stores/miscDraft'

const { isCampaignFooterDisplayed: IS_CAMPAIGN_FOOTER_DISPLAYED } =
  storeToRefs(useMiscDraftStore())
</script>

<template>
  <div class="tw-text-sm tw-text-white">
    <div
      class="orangeContainer tw-bg-orange-500"
      :class="{ IS_CAMPAIGN_FOOTER_DISPLAYED: IS_CAMPAIGN_FOOTER_DISPLAYED }"
    >
      <div class="mainParentContainer">
        <div
          class="tw-border-0 tw-border-b tw-border-solid tw-border-white 600:tw-grid 600:tw-grid-cols-4"
        >
          <FooterTop
            class="tw-w-full tw-pb-10 tw-pt-6 tw-text-center sm:tw-pb-6 sm:tw-text-left 600:tw-col-span-1 600:tw-pt-10"
          />
          <FooterContent
            class="tw-pb-6 sm:tw-pb-10 600:tw-col-span-3 600:tw-pt-10"
          />
        </div>
        <FooterBottom class="tw-py-6" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.orangeContainer {
  padding-bottom: 86px;

  @media screen and (min-width: 1180px) {
    @apply tw-pb-0;
  }

  &.IS_CAMPAIGN_FOOTER_DISPLAYED {
    padding-bottom: 140px;

    @screen 375 {
      padding-bottom: 151px;
    }

    @media screen and (min-width: 1180px) {
      padding-bottom: 84px;
    }
  }
}
</style>
